import React from 'react';
import queryString from 'querystring';
import * as userActions from '../actions/user';
import * as _ from 'lodash';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import LoadingScreen from '../components/LoadingScreen';
import LoginForm from '../components/forms/LoginForm';
import LOGO from '../logo.svg';
import ForgotPasswordForm from "../components/forms/ForgotPasswordForm";


class LoginComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      forgotPassword:false,
      referrer: queryString.parse(props.location.search.substr(1)).r
    }
  }

  renderLoading = () => {
    return <LoadingScreen/>
  };

  componentDidMount() {
    const {dispatch} = this.props;
    dispatch(userActions.setUserLoading(false));
  }

  onFormSubmit = (values, dispatch) => {
    const { forgotPassword } = this.state;
    if(forgotPassword){
      dispatch(userActions.resetPassword(values));
    }else{
      dispatch(userActions.loginUser(values.email, values.password));
    }
  };


  renderLogin = () => {
    const {isLoading, error} = this.props;
    const {forgotPassword} = this.state;
    return(
      <FormHolder message={error && error.message} isLoading={isLoading}>
        <LoginForm onSubmit={this.onFormSubmit}/>
        <PageExtras isForgotPassword={forgotPassword} toggleForm={this.toggleForm}/>
      </FormHolder>
    )
  };

  toggleForm = (e) => {
    e.preventDefault();
    this.setState({
      forgotPassword:!this.state.forgotPassword
    });
  };

  renderForgotPassword =() => {
    const {isFetching, error} = this.props;
    const {forgotPassword} = this.state;
    return (
      <FormHolder message={error && error.message} isLoading={isFetching}>
        <ForgotPasswordForm onSubmit={this.onFormSubmit}/>
        <PageExtras isForgotPassword={forgotPassword} toggleForm={this.toggleForm}/>
      </FormHolder>
    )
  };

  renderPasswordResetSuccessfulMessage = () => {
    return (
      <FormHolder>
        <MessageHolder>
          <h3>Your password has been reset.</h3>
          <p>
            A default password has been sent to you via SMS/mail. Use this to login.
            Remember to change the password once you're in.
            <br/>
            For enquiries, don't hesitate to send a mail to <a href="mailto:hello@reliancehmo.com?Subject=Enrollee Enquiry [from web app]">hello@reliancehmo.com</a>
            <br/>
            Thanks for choosing RelianceHMO.
          </p>
        </MessageHolder>
      </FormHolder>
    )
  };




  render() {

    const {isFetching, checked, authenticated, user, recoverySuccessful} = this.props;

    const {forgotPassword,referrer} = this.state;

    let from = "/welcome";

    if (referrer)
      from = decodeURI(referrer);

    // if(location.state && location.state.from){
    //     from = location.state.from;
    // }else{
    //     if(referrer)
    //         from = decodeURI(referrer);
    // }

    if (isFetching) return this.renderLoading();

    if (checked && authenticated && !_.isEmpty(user)) {
      //check if referrer is dashboard,
      // if yes, check if user can access dashboard,
      // if yes...proceed,else go to /dashboard/enrollees
      window.location.replace(from);
      return null;
    }

    if (recoverySuccessful) return this.renderPasswordResetSuccessfulMessage();

    if (forgotPassword) return this.renderForgotPassword();

    return this.renderLogin();
  };
}

const FormHolder = (props) => {
  return (
    <div className="container">
      <div className="login_form">
        <div className="form_logo">
          <a href="/"><img src={LOGO} alt="Reliance HMO's Logo"/></a>
        </div>
        <div style={{padding:"10px",backgroundColor:"red",color:"white",visibility:(!props.isLoading && props.message)?"visible":"hidden"}}>
          {props.message}
        </div>
        <div className="form_wrapper">

          {props.children}

        </div>
      </div>
    </div>
  )
};

const PageExtras = (props) => {
  return (
    <div>
      <p>
        {props.isForgotPassword ? "" : "Forgot Password?"}
        <span className="mini_cta">
            <Link to={'#'} onClick={props.toggleForm}>
                {props.isForgotPassword ? "Back" : "Click Here" }
            </Link>
        </span>
      </p>

      {/*<p className="text_left">Dont have a Plan?*/}
        {/*<span className="mini_cta">*/}
            {/*<a href="mailto:hello@reliancehmo.com?subject=Request Quote"> Request Quote</a>*/}
        {/*</span>*/}
      {/*</p>*/}
    </div>
  )
};

const MessageHolder = (props) => {
  return (
    <div style={{padding:"10px",margin:"10px",backgroundColor:"white",color:"#111"}}>
      <div>
        {props.children}
      </div>
    </div>
  )
};

function mapStateToProps(state) {
  const {isFetching, error, recoverySuccessful} = state.user || {};
  const {checked, authenticated, user} = state.session || {};
  return {isFetching, error, checked, authenticated, user, recoverySuccessful };
}

export default withRouter(connect(mapStateToProps)(LoginComponent));