import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

const EnrolleeDetailComponent = ({selectedEnrollee, onCompleteProfile}) => (
<React.Fragment>
<div className="dashboard-card profile-section">
    {/*<img src={selectedEnrollee.profile_picture} className="profile__img" alt="Enrollee"/>*/}
    <div className="profile-detail-wrap">
      <div className="profile-item">
        <div className="profile-item-wrapper">
            <p className="profile-item__label">Enrollee Name</p>
            <p className="profile-item__text">{`${selectedEnrollee.first_name} ${selectedEnrollee.last_name}`}</p>
        </div>

        <div className="profile-item-wrapper">
            <p className="profile-item__label">Plan</p>
            <p className="profile-item__text">{selectedEnrollee.plan_name}</p>
        </div>

        <div className="profile-item-wrapper">
            <p className="profile-item__label">Status</p>
            <p className="profile-item__text">{selectedEnrollee.status}</p>
        </div>

        {selectedEnrollee.profile_hash &&
          <div className="profile-item-wrapper">
            <button
              className="dashboard__primary-btn" style={{ padding: "0.8rem" }}
              onClick={() => onCompleteProfile(selectedEnrollee.id, encodeURIComponent(selectedEnrollee.profile_hash))}>
              Complete Profile
            </button>
          </div>
        }
      </div>

      <div className="profile-item">
        <div className="profile-item-wrapper">
          <p className="profile-item__label">Email Address</p>
          <p className="profile-item__text">
            {selectedEnrollee.email_address ? selectedEnrollee.email_address : "N/A"}
          </p>
        </div>

        <div className="profile-item-wrapper">
          <p className="profile-item__label">Cover Start Date</p>
          <p className="profile-item__text">
            {selectedEnrollee ? moment(selectedEnrollee.cover_start_date).format("ddd, MMMM Do YYYY") : "Loading..."}
          </p>
        </div>

        <div className="profile-item-wrapper">
          <p className="profile-item__label">HMO ID</p>
          <p className="profile-item__text">
            {selectedEnrollee.hmo_id ? selectedEnrollee.hmo_id : "N/A"}
          </p>
        </div>
      </div>

      <div className="profile-item">
        <div className="profile-item-wrapper">
          <p className="profile-item__label">Phone Number</p>
          <p className="profile-item__text">
            {selectedEnrollee.phone_number}
          </p>
        </div>

        <div className="profile-item-wrapper">
          <p className="profile-item__label">Cover End Date</p>
            <p className="profile-item__text">
              {moment(selectedEnrollee.cover_end_date).format("ddd, MMMM Do YYYY")}
              </p>
              </div>
            </div>
       </div>
      <button 
      className="btn"
      onClick={()=> window.location.href = `/dashboard/invoices/${selectedEnrollee.invoice_code}`}>Change Payment Frequency</button>
     </div>
  </React.Fragment>
  
);

EnrolleeDetailComponent.propTypes = {
  selectedEnrollee: PropTypes.object,
  onCompleteEnrolleeProfile: PropTypes.func,
};

export default EnrolleeDetailComponent;