import React, { Component } from 'react';
import PageHeader from '../components/boxes/PageHeader';
import PageBody from '../components/boxes/PageBody';
import MainContent from '../components/boxes/MainContent';
import FundWalletModal from '../components/modals/FundWalletModal';
import { ApiService, ENDPOINTS } from '../services/ApiService';
import RestrictedComponent from "../components/boxes/RestrictedComponent";
import { BASE_URL_RELIANCE_PUBLIC } from "../services/utilityService";

class OverviewContainer extends Component {
  constructor(props){
    super(props);
    const {user: {preferences : { selectedPartner: { credentials } }}, user} = props;
    this.v1_service = new ApiService(user.credentials.access_token,credentials.api_key,credentials.secret_key, BASE_URL_RELIANCE_PUBLIC);
    this.state = {
      overviewDetails: {},
      showFundWalletModal: false
    }
  }

  componentDidMount() {
    this.getOverviewDetials();
  }

  handleClick = (url) => {
    this.props.history.push(`${url}`);
  }

  getOverviewDetials = () => {
    this.v1_service.get(ENDPOINTS.overview)
      .then((response) => {
        this.setState({
          overviewDetails: response.data
        })
      })
      .catch((error) => {
        console.log(error);
      })
  };

  toggleFundWalletModal = () => {
    this.setState({
      showFundWalletModal: !this.state.showFundWalletModal
    })
  };

  render() {
    const { user: { preferences: { selectedPartner } }} = this.props;
    const { overviewDetails, showFundWalletModal } = this.state;

    return (
      <MainContent>
        <PageHeader title="Overview"/>
        <PageBody>
          <RestrictedComponent
            condition={selectedPartner.role === "partner"}
            displayErrorMessage>
            <div style={{padding: "2rem"}}>
              <div style={{ marginBottom: "2rem" }}>
                <div style={{ marginBottom: "2rem" }}>
                  <h2>Quick Links</h2>
                </div>

                {
                  showFundWalletModal &&
                  <FundWalletModal
                    onClose={this.toggleFundWalletModal}
                    showFundWalletModal
                  />
                }

                <div className="dashboard-card" >
                  <button
                    className="dashboard__secondary-btn"
                    style={{marginRight: "2rem", marginBottom: "1rem"}}
                    onClick={() => this.handleClick('/dashboard/enrollees/add-enrollee')}
                  >
                    Add Enrollee
                  </button>
                  <button
                    className="dashboard__secondary-btn"
                    style={{marginRight: "2rem", marginBottom: "1rem"}}
                    onClick={() => this.handleClick('/dashboard/agents/add-agent')}
                  >
                    Add Agent
                  </button>
                  <button
                    className="dashboard__secondary-btn"
                    style={{marginRight: "2rem", marginBottom: "1rem"}}
                    onClick={this.toggleFundWalletModal}
                  >
                    View Wallet
                  </button>
                </div>
              </div>

              <div style={{ marginBottom: "2rem" }}>
                <div style={{ marginBottom: "2rem" }}>
                  <h2>Overview</h2>
                </div>

                <div className="stats-wrap">
                  <div className="dashboard-card stats-card" style={{cursor: "pointer" }}>
                    <div className="dashboard-card__text--left-align">
                      <p className="dashboard-card__title-text">
                        No. of Enrollees
                      </p>
                      <p className="dashboard-card__data-text">
                        {overviewDetails.no_of_enrollees}
                      </p>
                    </div>
                    <i className="fas fa-user-check fa-3x dashboard-card__icon "></i>
                  </div>

                  <div className="dashboard-card stats-card" style={{ }}>
                    <div className="dashboard-card__text--left-align">
                      <p className="dashboard-card__title-text">
                        No. of Agents
                      </p>
                      <p className="dashboard-card__data-text">
                        {overviewDetails.no_of_agents}
                      </p>
                    </div>
                    <i className="fas fa-user-check fa-3x dashboard-card__icon "></i>
                  </div>

                  <div className="dashboard-card stats-card">
                    <div className="dashboard-card__text--left-align">
                      <p className="dashboard-card__title-text">
                        No. of Plans sold
                      </p>
                      <p className="dashboard-card__data-text">
                        {overviewDetails.no_of_policies_sold}
                      </p>
                    </div>
                    <i className="fas fa-user-check fa-3x dashboard-card__icon "></i>
                  </div>
                </div>
              </div>
            </div>
          </RestrictedComponent>
        </PageBody>
      </MainContent>
    );
  }
}

export default OverviewContainer;