import React  from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import LoginContainer from './containers/LoginContainer';
import DashboardContainer from './containers/DashboardContainer';
import PageNotFoundComponent from './components/PageNotFoundComponent';
import './App.css';
import PaymentSuccessfulComponent from "./components/PaymentSuccessfulComponent";
import WelcomeContainer from "./containers/WelcomeContainer";
import {withUser} from "./components/utils/AuthHOC";
import LogoutContainer from "./containers/LogoutContainer";

const App = () => (
    <Router history="">
        <div className="content">
            <Switch>
                <Route path="/" exact render={(props)=> <Redirect to="/dashboard/enrollees" /> } />
                <Route component={LoginContainer} path="/login" exact />
                <Route component={WelcomeContainer} path="/welcome" exact/>
                <Route component={PaymentSuccessfulComponent} path="/payment-successful" exact />
                <Route component={LogoutContainer} path="/logout" exact />
                <Route component={withUser(DashboardContainer)} path="/dashboard" />
                <Route component={PageNotFoundComponent}/>
            </Switch>
            <ToastContainer 
                position="top-right"
                autoClose={5000}
                hideProgressBar
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable
            />
        </div>
    </Router>
);

export default App;
