import React from 'react';
import numberFormatter from 'number-formatter';
import PropTypes from 'prop-types';
import userService from '../services/userService';
import LoadingScreen from './LoadingScreen';
import utilityService, { getDiscountedPrice } from "../services/utilityService";

class PaymentFrequencySelectorComponent extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      isLoading:false,
      data:null,
      selectedFrequency:null,
      defaultFreq:this.props.exclude
    }
  }

  componentDidMount(){
    const {invoice} = this.props;


    let output = {
      productCount:{total:0},
      price:{
        monthly:0,
        quarterly:0,
        yearly:0
      }
    };


    utilityService.getPlans()
      .then(products=>{
        products.forEach(product=>{
          let productKey = product.name.trim().toLowerCase();//.replace(/\s/g,'');
          let invoiceProduct = invoice.products.filter(p=>p.name.indexOf(product.name) > -1)[0] || null;
          if(invoiceProduct){
            output.productCount[productKey] = invoiceProduct.no_of_enrollees;
            output.productCount.total+=invoiceProduct.no_of_enrollees;
            Object.keys(output.price)
              .forEach(k=>{
                output.price[k] = Number(output.price[k]) + (product.price[k] *= invoiceProduct.no_of_enrollees);
              });
          } else return null
        });

        this.setState({
          data: output
        })
      })
  }

  onChangeFrequency = (freq) => {
    this.setState({
      selectedFrequency:freq,
      defaultFreq:null
    })
  };

  onChangeConfirmed = () => {
    this.setState({
      isLoading: true
    });
    const {invoice, onFrequencyChanged} = this.props;
    const {selectedFrequency} = this.state;
    const {user: {preferences : { selectedPartner: { credentials } }}, user} = this.props;
    userService.changePaymentFrequency(user.credentials.access_token, selectedFrequency, invoice.reference_code, credentials.api_key, credentials.secret_key)
      .then(response=>{
        onFrequencyChanged(null,selectedFrequency,response);
      })
      .catch(e=>{
        onFrequencyChanged(e.message);
      })
  };

  render(){

    const {data, isLoading, selectedFrequency, defaultFreq} = this.state;

    if(isLoading || !data) return (
      <div style={{ position:"relative",width:"100%",height:"100px",minWidth:"300px"}}>
        <LoadingScreen/>
      </div>
    );


    return (
      <>
        <p className="dashboard-card__body-text">To change how often you pay, please select one of the options below</p>
        <div className="payment-freq-modal-wrap ">
          {Object.keys(data.price).map((freq,index)=>{
            return (
              <div key={index+freq}>
                <h3 className="dashboard-card__title-text">{freq[0].toUpperCase()}{freq.slice(1)}</h3>
                <p className="plan-pricetag">&#8358;{numberFormatter("#,###.00",data.productCount.total >= 4 ? getDiscountedPrice(data.price[freq],20) : data.price[freq])}</p>
                <p className="dashboard-card__body-text">For {data.productCount.total} Plan(s)</p>
                <p className="payment-freq-modal-wrap__small-text">{
                  Object.keys(data.productCount).map((plan,index)=> {
                    if(plan === 'total') return null;
                    return <span key={plan+index}>
                        {`${data.productCount[plan]} ${plan} ${index !== Object.keys(data.productCount).length - 1 ? ',':''} `}
                      </span>
                  })
                }</p>
                <button onClick={()=>this.onChangeFrequency(freq)} className={`${selectedFrequency === freq || defaultFreq === freq? 'dashboard__primary-btn dashboard-control__button': 'dashboard__secondary-btn'}`}>{selectedFrequency === freq || defaultFreq === freq? 'Selected' : 'Select'}</button>
              </div>
            )
          })}
        </div>
        <div style={{display: selectedFrequency || !defaultFreq ? 'block': 'none'}}>
        <p className="payment-freq-modal-wrap__small-text">Click the 'Proceed' button below to change your payment frequency to {selectedFrequency}.</p>
        <p className="payment-freq-modal-wrap__small-text"> Please note that this change will take effect at your next renewal.</p>
        <div className="button-header" style={{justifyContent:"center"}}>
            <button
              className="dashboard__primary-btn"
              onClick={this.onChangeConfirmed}>
              PROCEED
            </button>
          </div>
        </div>
      </>
    );
  }
}

PaymentFrequencySelectorComponent.propTypes = {
  invoice: PropTypes.object,
  user: PropTypes.object,
  onFrequencyChanged: PropTypes.func,
  exclude: PropTypes.string
};

export default PaymentFrequencySelectorComponent;