import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as _ from 'lodash';
import Immutable from 'seamless-immutable';
import {
  decodePaymentFrequency,
} from "../services/utilityService";
import * as invoiceActions from '../actions/invoice';
import userService from '../services/userService';
import InvoiceDetailsComponent from '../components/InvoiceDetailsComponent';
import InvoicePlansComponent from '../components/InvoicePlansComponent';
import PageNotFound from '../components/PageNotFoundComponent';
import LoadingScreen from '../components/LoadingScreen';
import PageHeader from '../components/boxes/PageHeader';
import PageBody from '../components/boxes/PageBody';
import MainContent from '../components/boxes/MainContent';
import NotificationBanner from '../components/boxes/NotificationBanner';
import Modal from "../components/boxes/Modal";
import PaymentFrequencySelectorComponent from "../components/PaymentFrequencySelectorComponent";
import InvoiceRenewalContainer from './InvoiceRenewalContainer';

class InvoiceDetailsContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      addEmployee: false,
      isLoading: false,
      message: null,
      showPaymentFrequencyModal:false,
      notification:null,
    };
  };

  componentDidMount() {
    const {dispatch, match} = this.props;
    const {user: {preferences : { selectedPartner: { credentials } }}, user} = this.props;
    dispatch(invoiceActions.getUserInvoiceByRefCode(user.credentials.access_token ,credentials.api_key, credentials.secret_key, match.params.invoiceId));
    
  }


  renderLoading() {
    return (
      <MainContent>
        <PageBody>
          <LoadingScreen/>
        </PageBody>
      </MainContent>
    )
  }

  renderInvoiceNotFound() {
    return (
      <MainContent>
        <PageNotFound/>
      </MainContent>
    )
  }

  renewPlan = () => {
    const {selectedInvoice} = this.props;
    this.setState({
      isLoading: true
    });
    userService.renewPlan(selectedInvoice.reference_code)
      .then(data => {
        this.setState({
          notification: null
        });
        window.location.href = data.authorization_url;
      })
      .catch(e => {
        this.setState({
          isLoading: false,
          notification: {type: "error", message: e.response.data.message}
        })
      })
  };

  reactivatePlan = () => {
    const {history} = this.props;
    this.setState({
      isLoading: true
    });
    history.push("/dashboard/invoices/edit/new-invoice");
  };

  buyNewPlanAndAssign = (json) => {
    this.setState({
      isLoading: true
    });
    userService.buyPlansAndExtensions(json)
      .then(response => {
        if (!response.use_new_card) {
          window.location.href = "/payment-successful";
        } else {
          window.location.href = response.authorization_url;
        }
      }, e => {
        this.setState({
          isLoading: false,
          notification: {type: "error", message: e.message}
        })
      });
  }

  variableBilling = (enrolleeIds, oneTime) => {
    const {selectedInvoice} = this.props;
    this.setState({
      isLoading: true
    });
    userService.variableBillingPay(selectedInvoice.reference_code, enrolleeIds, oneTime)
      .then(data => {
        this.setState({
          notification: null
        });
        window.location.href = data.authorization_url;
      })
      .catch(e => {
        this.setState({
          isLoading: false,
          notification: {type: "error", message: e.message}
        })
      })
  };

  togglePaymentFrequencyModal = () => {
    this.setState({
      showPaymentFrequencyModal: !this.state.showPaymentFrequencyModal
    })
  };

  onFrequencyChanged = (err, newFreq, message) => {
    this.setMessage({
      type: err ? "Error" :  "Success",
      message: err ? err : message,
    });
    this.togglePaymentFrequencyModal();
  };

  onAddEnrollee = () => {
    const {selectedInvoice, history} = this.props;
      history.push(`/dashboard/invoices/edit/add-enrollee?refCode=${selectedInvoice.reference_code}`)
  };

  setMessage = (notification) => {
    this.setState({
      notification
    })
  };

  render() {

    const {error, selectedInvoice, user} = this.props;
    const {isLoading, notification, showPaymentFrequencyModal} = this.state;

    if (error && error.code === 404) return this.renderInvoiceNotFound();

    if (isLoading) return this.renderLoading();

    if (!_.isEmpty(selectedInvoice)) {
      if (selectedInvoice.active_status_id === 5 || selectedInvoice.active_status_id === 4) {
        return (
          <MainContent>
            <PageHeader title="Invoice Renewal" />
            <PageBody>
              {notification &&
                <NotificationBanner
                  title={notification.type}
                  message={notification.message}
                  type={notification.type.toLowerCase()}
                  onDismiss={()=>this.setMessage(null)}/>
              }
              <InvoiceRenewalContainer
                user={user} 
                invoice={selectedInvoice} 
                onReactivate={this.reactivatePlan}
                onRenew={this.renewPlan}
                onBuyNewPlan={this.buyNewPlanAndAssign}
                onVariableBilling={this.variableBilling}
              />
            </PageBody>
          </MainContent>
        )
      }

      return (
        <MainContent>
          <PageHeader title="Invoice Details"/>
          <PageBody>
            {notification &&
              <NotificationBanner
                title={notification.type}
                message={notification.message}
                type={notification.type.toLowerCase()}
                onDismiss={()=>this.setMessage(null)}/>
            }
            {showPaymentFrequencyModal &&
              <FrequencyModal
                onClose={this.togglePaymentFrequencyModal}
                onFrequencyChanged={this.onFrequencyChanged}
                invoice={selectedInvoice}
                user={user}
                selectedFrequency={decodePaymentFrequency(selectedInvoice.payment_frequency_id).toLowerCase()}/>
            }

            {selectedInvoice.new_frequency_id &&
              <div style={{padding:"2rem",background:"#e2e2e2",color:"#101010",fontSize:"1.2rem",marginBottom: "2rem"}}>
                <p>
                  PLEASE NOTE: Your payment frequency will change to <b>{decodePaymentFrequency(selectedInvoice.new_frequency_id).toUpperCase()}</b> by your next renewal.
                </p>
              </div>
            }

            <div className="dashboard-card dashboard-table-wrap">
              <h2 className="dashboard-card__title-text">Invoice Details for {selectedInvoice.reference_code}</h2>
              <div className="dashboard-control">
                  <p className="dashboard-card__title-text" style={{marginBottom: "0"}}>
                      You pay {decodePaymentFrequency(selectedInvoice.payment_frequency_id)}
                  </p>

                  <button className="dashboard__primary-btn dashboard-control__button" onClick={this.togglePaymentFrequencyModal}>Change
                      Payment
                      Frequency</button>
              </div>

              <InvoiceDetailsComponent invoice={selectedInvoice}/>
            </div>
            <div className="dashboard-card dashboard-table-wrap" style={{marginTop: "2rem"}}>
              <InvoicePlansComponent addEnrollee={this.onAddEnrollee} plans={selectedInvoice.products}/>
            </div>
          </PageBody>
        </MainContent>
      );
    }
    return this.renderLoading();
  }

}




const FrequencyModal = ({selectedFrequency, onClose, invoice,user, onFrequencyChanged}) => {

  return (
    <Modal open={true} onClose={onClose} title="Change Payment Frequency">
      <PaymentFrequencySelectorComponent
        user={user}
        invoice={invoice}
        exclude={selectedFrequency}
        onFrequencyChanged={onFrequencyChanged}
      />
    </Modal>
  )
};



const mapStateToProps = ({invoice}) => ({
  isFetching: invoice.isFetching,
  selectedInvoice: invoice.selectedInvoice ? Immutable.asMutable(invoice.selectedInvoice, {deep: true}) : {},
  error: invoice.error
});

export default withRouter(connect(mapStateToProps)(InvoiceDetailsContainer));