import React from 'react';
import { withRouter } from 'react-router-dom';
import { PartnersService, PARTNER_ENDPOINTS } from '../services/PartnersService';
import LoadingScreen from '../components/LoadingScreen';
import PageHeader from '../components/boxes/PageHeader';
import PageBody from '../components/boxes/PageBody';
import MainContent from '../components/boxes/MainContent';

class ProfileContainer extends React.Component {

  constructor(props) {
    super(props);
    this.partnersService = new PartnersService(props.user.credentials.accessToken);
    this.state = {
      profileDetails: {},
      isFetching: true
    }
  }
  
  componentDidMount() {
    this.getProfileDetails();
  }

  getProfileDetails = () => {
    const { user: { preferences: { selectedPartner }}, user } = this.props;
    this.partnersService.get(PARTNER_ENDPOINTS.agents.agent(selectedPartner.id, user.id))
      .then((response) => {
        this.setState({
          profileDetails: response.data,
          isFetching: false
        });
      })
      .catch((error) => {
        console.log(error)
      });
  };

  render() {
    const { profileDetails, isFetching } = this.state;

    return (
      <MainContent>
        <PageHeader title="My Profile"/>
        <PageBody>
          {
            isFetching ?
              <LoadingScreen/>
              :
              <React.Fragment>
                <div>
                  <div className="dashboard-card">
                    <h2 className="agent-header-text">Profile Details</h2>
                    <div className="profile-section">
                      <div className="profile-detail-wrap">
                        <div className="profile-item agent-profile-item">
                          <div className="profile-item-wrapper agent-wrapper">
                            <p className="profile-item__label">Name</p>
                            <p className="profile-item__text">{profileDetails.first_name} {profileDetails.last_name}</p>
                          </div>

                          <div className="profile-item-wrapper agent-wrapper">
                            <p className="profile-item__label">Email Address</p>
                            <p className="profile-item__text">{profileDetails.email_address ? profileDetails.email_address : 'N/A'}</p>
                          </div>

                          <div className="profile-item-wrapper agent-wrapper">
                            <p className="profile-item__label">Phone Number</p>
                            <p className="profile-item__text">{profileDetails.phone_number ? profileDetails.phone_number : 'N/A'}</p>
                          </div>

                          <div className="profile-item-wrapper agent-wrapper">
                            <p className="profile-item__label">Code</p>
                            <p className="profile-item__text">{profileDetails.referral_code ? profileDetails.referral_code : 'N/A'}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <h2 className="agent-header-text">Performance</h2>
                    <div className="dashboard-card dashboard-table-wrap">
                      {profileDetails.sales.length > 0 ? (
                        <div className="dashboard-table-overflow">
                          <table className="dashboard-table">
                            <thead>
                            <tr>
                              <th className="dashboard-table-item dashboard-table-item__header">Plan Name</th>
                              <td className="dashboard-table-item dashboard-table-item__header">Number Sold</td>
                            </tr>
                            </thead>
                            <tbody>
                            {profileDetails.sales.map((sale,index) => (
                              <tr key={`${index}_${sale.plan_name}`}>
                                <th className="dashboard-table-item dashboard-table-item__header">{sale.plan_name}</th>
                                <td className="dashboard-table-item dashboard-table-item__body">{sale.amount_sold}</td>
                              </tr>
                            ))}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <p className="profile-item__text">You have not made any sale yet.</p>
                      )}
                    </div>
                  </div>
                </div>
              </React.Fragment>
          }
        </PageBody>
      </MainContent>
    );
  }
}

export default withRouter(ProfileContainer);