import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import queryString from 'querystring';
import LOGO from '../logo.svg'
import SignUpForm from '../components/forms/SignUpForm';
import uService from '../services/utilityService';
import LoadingScreen from '../components/LoadingScreen';
import { ApiService, ENDPOINTS } from '../services/ApiService';
import {BASE_URL_RELIANCE_PUBLIC} from '../services/utilityService';

class SignUpContainer extends React.Component {

  constructor(props) {
    super(props);
    //check match for params, if exists get enrollee type and/or hash
    //check props for params, if exists get enrollee type and accessToken
    const {location} = props;
    const hash = queryString.parse(location.search.substr(1)).hash;

    const {user: {preferences : { selectedPartner: { credentials } }}, user} = props;
    this.service = new ApiService(user.credentials.access_token, credentials.api_key,credentials.secret_key);
    this.v1_service = new ApiService(user.credentials.access_token,credentials.api_key,credentials.secret_key, BASE_URL_RELIANCE_PUBLIC);
    this.private_service = new ApiService(user.credentials.access_token,credentials.api_key,credentials.secret_key, null, true);

    this.state = {
      isLoading: true,
      formData: {
        user: {
          hash: hash || null
        }
      },
      message: null,
      error: null
    };
  }

  componentDidMount() {
    const {user} = this.state.formData;
    const {location} = this.props;
    const subscriptionId = location.search.split("&")[1].split("=")[1];

    Promise.all([
        uService.getUtilities(),
        this.v1_service.get(ENDPOINTS.enrollees.enrolleeDetails(this.props.match.params.enrolleeId, subscriptionId)),
        uService.getProviders(this.props.user.credentials.access_token),
        this.v1_service.get(ENDPOINTS.utilities.states)
      ])
      .then((response) => {
        this.setState({
          isLoading: false,
          formData: {
            user: {
              ...user,
              ...response[1].data,
            },
            utils: response[0],
            providers: response[2],
            states: response[3].data
          },
          error: null
        });
      }, (e) => {
        if (e && e.code === 403) {
          window.location = "/dashboard/enrollees";
        } else {
          this.setError({
            message: e.message || "An Error Occurred",
            status: e.code,
            code: e.code
          });
        }
      });
  }


  setError = (e) => {

    this.setState({
      isLoading: false,
      abort: e === null || e === undefined,
      error: e
    });
    this.scrollToError();
  };

  scrollToError = () => {
    this.errorHolder.scrollIntoView({behaviour: 'smooth'});
  };


  onSubmitForm = (values, dispatch) => {
    this.setState({
      isLoading: true
    });


    this.private_service.post(ENDPOINTS.enrollees.registerEnrollee, values)
      .then(message => {

        this.setState({
          error: null,
          message: message,
          successful: true,
          abort: false,
          isLoading: false
        })
      }, (e) => {
        this.setError({
          message: e.message,
          code: e.status
        });
      });
  };


  render() {
    const {isLoading, formData, error, abort, successful} = this.state;

    if (isLoading)
      return (
        <SignUpFormHolder>
          <LoadingScreen/>
        </SignUpFormHolder>
      );

    if (!isLoading && abort)
      return (
        <SignUpFormHolder>
          <div style={{padding: "10px", margin: "10px", backgroundColor: "red", color: "white"}}>
            <h3>
              An error seems to have occurred.
              Please try again. If issues persist,
              kindly send a mail to <a style={{color: "white"}}
                                       href="mailto:hello@reliancehmo.com?Subject=Enrollee Sign Up Help [from web app]">hello@reliancehmo.com</a>.
            </h3>
          </div>
        </SignUpFormHolder>
      );

    if (!isLoading && successful)
      return (
        <SignUpFormHolder>
          <div style={{padding: "10px", margin: "10px", backgroundColor: "#fffffa", color: "#111"}}>
            <div>
              <h3>Congratulations!</h3>
              <p>
                Your Enrollee has successfully signed up for
                a RelianceHMO insurance plan and can start using the plan immediately.
                <br/>
                <br/>
                We just sent an email and SMS to the email address and phone number you provided us.
                Kindly read the very important information carefully as they contain all you need
                to start using your insurance.
                <br/>
                <br/>
                If you have any issues our Reliance Help Centre agents are always happy to help via:
                <br/>
                Email: <a href="mailto:hello@reliancehmo.com?Subject=Enrollee Enquiry [from web app]">hello@reliancehmo.com</a>
                <br/>
                Telephone: <a href="tel:070073542623">0700-7354-2623</a>
                <br/>
                Whatsapp: <a href="tel:07035369587">070-353-69587</a>
                <br/>
                Facebook: <a href="https://www.facebook.com/pg/reliancehmo">@reliancehmo</a>
                <br/>
                <br/>
                Thanks for choosing RelianceHMO.
              </p>
              <br/>
              <br/>
              <div style={{fontSize: "22px"}}>
                <div style={{float: "left"}}>
                  <Link to="/dashboard/enrollees">Go back</Link>
                </div>
              </div>
            </div>
          </div>
        </SignUpFormHolder>
      );

    return (
      <SignUpFormHolder user={formData.user}>
        {!isLoading && error &&
        <div ref={(el) => this.errorHolder = el} style={{
          padding: "10px",
          margin: "10px",
          backgroundColor: "red",
          color: "white",
          visibility: (!isLoading && error) ? "visible" : "hidden"
        }}>
          <h3>{error.message}</h3>
        </div>}

        <SignUpForm
          data={formData.utils}
          states={formData.states}
          providers={formData.providers}
          user={formData.user}
          onSubmit={this.onSubmitForm}
          onError={this.setError}
        />
      </SignUpFormHolder>
    )
  }
}

export default withRouter(SignUpContainer)


export const SignUpFormHolder = (props) => (
  <div className="container" style={{position: "relative"}}>
    <div className="signup">
      <div className="signup__header">
        <img src={LOGO} alt="logo"/>
        <p>&nbsp;| Enrollee Sign-Up Form</p>
      </div>
      <div className="signup__form">
        {props.children}
      </div>
    </div>
  </div>
);