import React from 'react';
import { SyncLoader } from 'react-spinners';
import '../index.css';

const LoadingScreen = () => {
    return(
      <div className="loader">
        <div style={{background: "#ffffff", width:"20rem", height: "20rem", display:"flex", justifyContent: "center", flexDirection:"column", alignItems:"center", alignContent:"center"}} >
          <SyncLoader color={'#107BC0'} height="20"/>
        </div>
      </div>
    )
};

export default LoadingScreen