import React, { Component } from 'react';
import { ApiService, ENDPOINTS } from '../services/ApiService';
import numberFormatter from 'number-formatter';
import PageHeader from '../components/boxes/PageHeader';
import PageBody from '../components/boxes/PageBody';
import MainContent from '../components/boxes/MainContent';
import FundWalletModal from '../components/modals/FundWalletModal';
import RestrictedComponent from "../components/boxes/RestrictedComponent";
import {BASE_URL_RELIANCE_PUBLIC, PAYMENT_STATUS,
  WALLET_TRANSACTION_TYPES
} from "../services/utilityService";
import AsyncTable from "../components/utils/AsyncTable";

const tableColumnData = [
  {
    key:"created_at",
    type:"date",
    alias:"date"
  },
  {
    key:"wallet_transaction_type_id",
    type:"string",
    alias:"transaction type",
    format:(id) =>{
      return WALLET_TRANSACTION_TYPES.getTransactionType(id);
    }
  },
  {
    key:"amount",
    type:"number",
    alias:"amount(₦)",
    format:(data)=>{
      return numberFormatter("#,##0.00",data)
    }
  },
  // {
  //   key:"balance",
  //   type:"number",
  //   alias:"balance(₦)",
  //   format:(data)=>{
  //     return numberFormatter("#,##0.00",data)
  //   }
  // },
  {
    key:"transaction_reference",
    type:"string",
    alias:"reference id"
  },

  {
    key:"payment_state_id",
    type:"string",
    alias:"status",
    format:(id)=>{
      return PAYMENT_STATUS.getPaymentStatus(id);
    }
  }
];

class TransactionListContainer extends Component {
  constructor(props){
    super(props);
    const {user: {preferences : { selectedPartner: { credentials } }}} = props;
    this.service = new ApiService(credentials.api_key,credentials.secret_key);
    this.state = {
      transactionsList: [],
      //isFetching: true,
      showFundWalletModal: false
    }
  }

  componentDidMount() {
    //this.getTransactionsList();
  }

  toggleFundWalletModal = () => {
    this.setState({
      showFundWalletModal: !this.state.showFundWalletModal
    })
  };

  transactionsListCheck = () => {
    const { showFundWalletModal } = this.state;
    const { user: { preferences: { selectedPartner } }} = this.props;

    return (
        <PageBody>
          {
            showFundWalletModal &&
              <FundWalletModal 
                onClose={this.toggleFundWalletModal} 
                showFundWalletModal                
              />
          }
          <RestrictedComponent
            condition={selectedPartner.role === "partner"}
            displayErrorMessage>
            <div className="dashboard-card dashboard-table-wrap">
              <div className="dashboard-table-overflow">
                <AsyncTable
                  dataSource={{
                    url:`${BASE_URL_RELIANCE_PUBLIC}/public${ENDPOINTS.wallet.transactions}`,
                    options:{
                      auth:{
                        username:selectedPartner.credentials.api_key,
                        password:selectedPartner.credentials.secret_key
                      }
                    }
                  }}
                  paginationDataKey="pagination"
                  isPaginated
                  limit={10}
                  paginationKeys={{
                    limit:"limit",
                    page:"page",
                    lastPage:"last_page",
                    currentPage:"current_page"
                  }}
                  tableColumnData={tableColumnData}/>
              </div>
            </div>
          </RestrictedComponent>
        </PageBody>
    );
  };

  render() {
    const { user: { preferences: { selectedPartner } }} = this.props;

    return (
      <MainContent>
        <PageHeader
          title="Transactions"
          showButton={selectedPartner.role === "partner"}
          buttonTitle="Wallet"
          onButtonClick={this.toggleFundWalletModal}/>
        {
            this.transactionsListCheck()
        }
      </MainContent>
    );
  }
}

export default TransactionListContainer;