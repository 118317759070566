import React from 'react';
import * as _ from 'lodash';
import moment from 'moment';
import queryString from 'querystring';
import EnrolleeDetailComponent from '../components/EnrolleeDetailComponent';
import LoadingScreen from '../components/LoadingScreen';
import PageHeader from '../components/boxes/PageHeader';
import PageBody from '../components/boxes/PageBody';
import MainContent from '../components/boxes/MainContent';
import {ApiService, ENDPOINTS } from '../services/ApiService';
import OptionsModal from '../components/modals/OptionsModal';
import DecisionModal from '../components/modals/DecisionModal';
import { toastBanner, BASE_URL_RELIANCE_PUBLIC, decodeResourceStatus } from '../services/utilityService';

class EnrolleeDetailContainer extends React.Component {
  constructor(props) {
    super(props);
    const {user: {preferences : { selectedPartner: { credentials } }}, user} = props;
    this.service = new ApiService(user.credentials.access_token,credentials.api_key,credentials.secret_key);
    this.v1_service = new ApiService(user.credentials.access_token,credentials.api_key,credentials.secret_key, BASE_URL_RELIANCE_PUBLIC);
    this.state = {
      selectedEnrollee: {},
      isLoading: false,
      showDecisionModal: false,
      showRCCModal: false
    }
  }

  componentDidMount() {
    this.setLoading(true);
    this.getSelectedEnrollee();
  }

  onRenew = () => {
    const { match: {params: {enrolleeId} } } = this.props;
    const enrollees =  { 
      enrollees: [
        {
          user_id: Number(enrolleeId)
        }
      ]
    };
    this.setLoading(true);
    this.setState({
      showDecisionModal: false
    })
    this.service.put(ENDPOINTS.plans.renewPlan, enrollees)
      .then((response) => {
        toastBanner("The enrollee's plan was successfully renewed", "success");
        setTimeout(()=>{
          window.location.replace("/dashboard/enrollees")
        },1500)
      })
      .catch((error) => {
        console.error(error.message);
        this.setState({
          isLoading: false,
        })
        toastBanner("The enrollee's plan wasn't renewed. Please contact support at hello@reliancehmo.com.", "error");
        setTimeout(()=>{
          window.location.replace("/dashboard/enrollees")
        },1500)
      })
  };

  getSelectedEnrollee() {
    const { match: { params: { enrolleeId} }, location } = this.props;
    const subscriptionId = queryString.parse(location.search.substr(1)).subscription_id;
    this.v1_service.get(ENDPOINTS.enrollees.enrolleeDetails(enrolleeId, subscriptionId))
    .then((response) => {
      this.setState({
        selectedEnrollee: response.data,
        isLoading: false
      })
    })
    .catch((error) => {
      toastBanner(error.message);
      setTimeout(() => {
        window.location.href = `/dashboard/enrollees`;
      }, 3000)
      
    })
  }

  setLoading = (isLoading) => {
    this.setState({
      isLoading
    })
  };

  renderLoading() {
    return (
      <MainContent>
        <PageBody>
          <LoadingScreen/>
        </PageBody>
      </MainContent>
    )
  };

  onCompleteProfile = (enrolleeId, hash) => {
    const subscriptionId = queryString.parse(this.props.location.search.substr(1)).subscription_id;
    this.props.history.push(`/dashboard/enrollees/${enrolleeId}/complete-profile?hash=${hash}&subscription_id=${subscriptionId}`);
  };

  toggleDecisionModal = () => {
    this.setState({
      showDecisionModal: !this.state.showDecisionModal
    })
  }

redirectToRCC = () => {
    this.setState({
      showRCCModal: !this.state.showRCCModal
    })
  }

  toggleRCCModal = () => {
    this.setState({
      showRCCModal: false
    })
  }

  render() {
    const { isLoading, selectedEnrollee, showDecisionModal, showRCCModal} = this.state;
    if(isLoading) return this.renderLoading();
    if(_.isEmpty(selectedEnrollee)) return (<div> </div>);
    return (
      <MainContent>
        <PageHeader title={`${selectedEnrollee.first_name} ${selectedEnrollee.last_name}'s Profile`}/>
        <PageBody>
          {
            showDecisionModal &&
            <DecisionModal
                title="Renew plan"
                onYes={this.onRenew}
                onClose={this.toggleDecisionModal}
                content=""
                isVisible={showDecisionModal}
              />
          }

          <OptionsModal
          title="How to renew expired plans"
          onClose={this.toggleRCCModal}
          isVisible={showRCCModal}>
          <div className="expired-information">
            <h5>Hello. Only suspended plans can be renewed from this dashboard.</h5>
            <h5>To renew expired plans, please reach out to our Reliance Help Centre agents via:</h5>
              <div>
                <p>Email: <span><a href="mailto:hello@reliancehmo.com?subject=Enquiry">hello@reliancehmo.com</a></span></p>
                <p>Telephone: <span><a href="tel:070073542623">0700-7354-2623</a></span></p>
                <p>Whatsapp: <span><a href="https://wa.me/23417001580">017001580</a></span></p>
                <p>Facebook: <span><a target="_blank" rel="noopener noreferrer" href="https://web.facebook.com/reliancehmo/?_rdc=1&_rdr">@reliancehmo</a></span></p>
              </div>
              <p>Thank you.</p>
            </div>
          </OptionsModal>
            
            {moment(selectedEnrollee.cover_end_date).diff(moment(), "days") <
              0 && selectedEnrollee.status.toLowerCase().trim() === decodeResourceStatus(5)   ? (
              <div className="dashboard-card button-header">
                <button
                  onClick={this.redirectToRCC}
                  className="dashboard__primary-btn"
                >
                  <i className="fa fa-exchange" style={{ color: "white" }} />
                  {"  "}
                  Renew Plan
                </button>
              </div>
            ) : ( selectedEnrollee.status.toLowerCase().trim() === decodeResourceStatus(4) ?
              <button
              onClick={this.toggleDecisionModal}
              className="dashboard__primary-btn">
              <i className="fa fa-exchange" style={{color: "white"}}/>
              {'  '}
              Renew Plan
            </button>
            : ""
            )}
            
          <EnrolleeDetailComponent
            selectedEnrollee={selectedEnrollee}
            onCompleteProfile={this.onCompleteProfile}
            />
          <br/>
        </PageBody>
      </MainContent>
    );
    
  }
}


export default EnrolleeDetailContainer;