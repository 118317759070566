import React from 'react';
import PropTypes from 'prop-types';
import Modal from "../boxes/Modal";

const OptionsModal = ({title, isVisible, onYes, onClose, children}) => {
  return (
    <Modal title={title} onClose={onClose} open={isVisible}>
      <div>
        {children}
      </div>
    </Modal>
  )
};


OptionsModal.propTypes = {
  onYes: PropTypes.func,
  onClose: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.string
};

export default OptionsModal;
