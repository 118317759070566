import React, { Component } from 'react';
import { ApiService, ENDPOINTS } from '../services/ApiService';
import AddEnrolleeForm from './forms/AddEnrolleeForm';
import LoadingScreen from './LoadingScreen';
import MainContent from './boxes/MainContent';
import { reset } from 'redux-form';
import { connect } from 'react-redux'
import {
  PLAN_TYPE_INDIVIDUAL,
  PACKAGE_TYPE_RETAIL, toastBanner} from '../services/utilityService';

class AddEnrolleeComponent extends Component {
  constructor(props){
    super(props);
    const {user: {preferences : { selectedPartner: { credentials } }}, user} = this.props;
    this.state = {
      enrollees: null,
      isLoading: false,
      isFetching: false,
      isV3: true,
      completeProfile: null,
      plans: [],
    }
    this.service = new ApiService(user.credentials.access_token,credentials.api_key,credentials.secret_key);
    
  }

  renderLoading() {
    return (
      <MainContent>
        <LoadingScreen/>
      </MainContent>
    )
  }

  componentWillUnmount () {
    const { dispatch } = this.props;
    dispatch(reset('addEnrolleeForm'));               
  }

  setPlans = (selectedPackage=PACKAGE_TYPE_RETAIL) => {
    this.setState({
      isLoadingPlans : true
    })
    const queryData = {
      type: PLAN_TYPE_INDIVIDUAL,
      package: selectedPackage,
    };
    this.service.get(ENDPOINTS.plans.getPlans, queryData)
      .then((response) => {
        this.setState({
          plans: response.data.plans,
          isLoadingPlans: false,
          isV3: true,
        });
      })
      .catch((error) => {
        console.log(error);
      })
  };

  onSubmitForm = (values) => {
    this.setState({
      isLoading: true,
      completeProfile: values.can_complete_profile
    });

    this.service.post(ENDPOINTS.enrollees.signUpEnrollee, {
      referral_code: this.props.user.referralCode,
      enrollees: [{...values, dependants: []}]
    })
      .then(response => {
        this.setState({
          isLoading: false,          
        });

        const hash = response.data.enrollees.successful.length === 0 ? [] : response.data.enrollees.successful[0].hash;
        const enrolleeId = response.data.enrollees.successful.length === 0 ? [] : response.data.enrollees.successful[0].user_id;
        const subscriptionId = response.data.enrollees.successful.length === 0 ? [] : response.data.enrollees.successful[0].subscription_id;

        if (response.data.enrollees.successful.length > 0) { 
          this.props.dispatch(reset('addEnrolleeForm'));               
          this.state.completeProfile ?
            this.props.history.push(`/dashboard/enrollees/${enrolleeId}/complete-profile?hash=${hash}&subscription_id=${subscriptionId}`)
            : 
            this.props.history.push(`/dashboard/enrollees`);
        }
      })
      .catch((error) => {
        this.setState({
          code: error.code,
          isLoading: false,
        });
        toastBanner(error.message);
      });
  };

  render() {
    const {isLoading} = this.state;
    if(isLoading) return (
      <MainContent>
        <LoadingScreen/>
      </MainContent>
    );

    return (
      <AddEnrolleeForm
        onSubmit={this.onSubmitForm}
        plans={this.state.plans}
        isLoadingPlans={this.state.isLoadingPlans}
        setPlans={this.setPlans}
      />
    );
  }
}

export default connect()(AddEnrolleeComponent);