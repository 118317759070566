import React from 'react';
import PropTypes from 'prop-types';
import Modal from "../boxes/Modal";

const NotificationModal = ({message, onClose, open}) => {
  return (
    <Modal title="Notification!" onClose={onClose} open={open}>
      <div style={{textAlign: "center", maxWidth: "500px"}}>
        <p style={{fontSize: "1.6rem", lineHeight: "2.4rem"}}>
          {message}
        </p>
        <button className="primary_button" onClick={onClose}>DISMISS</button>
      </div>
    </Modal>
  )
};

NotificationModal.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func
};

export default NotificationModal;