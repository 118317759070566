import React, {PureComponent} from 'react';
import axios from 'axios';
import moment from 'moment';
import numberFormatter from 'number-formatter';
import PropTypes from 'prop-types';
import LoadingScreen from '../LoadingScreen';
import SortTableData from './SortTableData';
import StringManipulator from './StringManipulator';


class AsyncTable extends PureComponent {
  constructor (props) {
    super(props);
    this.state = {
      isLoading:false,
      sortedData: [],
      tableData:[],
      currentPage:1,
      lastPage:null,
      isSorted:false,
      sortConfig:null
    }
  }

  componentDidMount(){
    const page = localStorage.getItem('lastPage') || 1;
    this.fetchData(page);
    localStorage.removeItem('lastPage');
  }

  componentDidUpdate(prevProps){
    if (this.props.dataSource.url !== prevProps.dataSource.url) {
      this.fetchData(1);
    }
  }

  fetchData = (page) => {
    this.setState({
      isLoading:true
    });
    const {dataSource,limit,paginationKeys,isPaginated, paginationDataKey} = this.props;
    const {isSorted, sortConfig} = this.state;
    let queryParams = isPaginated && paginationKeys ? `${paginationKeys.page}=${page}&${paginationKeys.limit}=${limit}`: "";
    axios.get(
      `${dataSource.url}${dataSource.url.includes("?") ? "&" : "?"}${queryParams}`,
        dataSource.options)
      .then(response=>{
        //do stuff with the response
        const paginationData = response.data[paginationDataKey || "pagination"] || response.data;
        this.setState({
          isLoading:false,
          currentPage:isPaginated ? paginationData[paginationKeys.currentPage] || page : 1,
          lastPage: isPaginated ? paginationData[paginationKeys.lastPage] || 1 : 1,
          tableData: !isSorted ? response.data.data : sortConfig.order === "asc" ? SortTableData.ascendingTableSort(sortConfig.key, response.data.data) : SortTableData.descendingTableSort(sortConfig.key, response.data.data)
        })
      })
  };

  onPageChange = (page) => {
    this.fetchData(page);
    localStorage.setItem('lastPage', page)
  }

  ascendingSort = (key) => {
    const sortedData = SortTableData.ascendingTableSort(key, this.state.tableData);
    this.setState({
      sortedData: sortedData,
      isSorted:true,
      sortConfig:{
        key,
        order:"asc"
      }
    })
  };

  descendingSort = (key) => {
    const sortedData = SortTableData.descendingTableSort(key, this.state.tableData);
    this.setState({
      sortedData: sortedData,
      isSorted:true,
      sortConfig:{
        key,
        order:"desc"
      }
    })
  };



  renderLoading = () => {
    return (
        <div className="loader">
          <LoadingScreen/>
        </div>
    );
  };

  renderTableData = (tableData, tableColumnData) => {
    const {onItemClick} = this.props;
    return (
      <tbody>
      {
        tableData.map((data, i) => {
          return (
            <tr key={i+1}>
              {
                tableColumnData.map((colData, i) => (
                  <td key={i+1} onClick={onItemClick ? () => onItemClick(data) : null}>
                    {colData.format
                      ? colData.format(data[colData.key])
                      : formatDataBasedOnType(data[colData.key || colData],colData.type)
                    }
                  </td>
                ))
              }
            </tr>
          )
        })
      }
      </tbody>
    )
  };

  render() {
    const {isLoading, currentPage, lastPage, tableData} = this.state;
    const {tableColumnData,isPaginated, styles, styleClass, paginatorStyle} = this.props;
    
    return (
      <React.Fragment>
        <div style={{overflowX:"scroll"}}>
          <table className={`${styleClass} async-table`} style={styles}>
            <thead>
            <tr>
              {
                tableColumnData.map((data, i) => (
                  <th key={i+1} className="table-header">
                    <span>{StringManipulator.replaceCharWithSpace(data.alias || data.key || data)}</span>
                    <span className="table-caret">
                    <i className="fa fa-caret-up" onClick={() => this.ascendingSort(data.key || data)}/>
                    <i className="fa fa-caret-down" onClick={() => this.descendingSort(data.key) || data}/>
                  </span>
                  </th>
                ))
              }
            </tr>
            </thead>
            {this.renderTableData(tableData,tableColumnData)}
          </table>
        </div>
        {isPaginated &&
          <div className={paginatorStyle}>
            <Paginator currentPage={currentPage} lastPage={lastPage} onPageChange={this.onPageChange}/>
          </div>
        }
        {isLoading && this.renderLoading()}
      </React.Fragment>
    )
  }
}

AsyncTable.propTypes = {
  tableColumnData: PropTypes.arrayOf(PropTypes.shape({
    key:PropTypes.string,
    dataType:PropTypes.string,
    alias:PropTypes.string,
    format:PropTypes.func
  })),
  onItemClick: PropTypes.func,
  styles: PropTypes.object,
  styleClass: PropTypes.string,
  paginatorStyle: PropTypes.string,
  dataSource: PropTypes.shape({
    url:PropTypes.string.isRequired,
    options:PropTypes.object //takes structure of axios request options/config
  }).isRequired,
  limit:PropTypes.number,
  isPaginated:PropTypes.bool,
  meta: PropTypes.object,
  paginationDataKey: PropTypes.string,
  paginationKeys: PropTypes.shape({
    currentPage: PropTypes.string,
    limit: PropTypes.string,
    count: PropTypes.string,
    lastPage: PropTypes.string,
    total: PropTypes.string,
    page: PropTypes.string
  })
};

const formatDataBasedOnType = (data,type="string") => {
  switch (type){
    case 'string':
      return data;
    case 'number':
      return numberFormatter("###,##0.",Number(data));
    case 'date':
      return moment(data).format("ddd, MMM Do YYYY");
    default:
      return data;
  }
};


class Paginator extends React.Component {
  render(){
    const {currentPage,lastPage, onPageChange,className} = this.props;
    return (
      <div className={className || "table-paginator"}>
        {<button disabled={currentPage === 1} onClick={()=>onPageChange(currentPage-5)}>Prev</button>}
        <React.Fragment>
          <button className="current">{currentPage}</button>
          {currentPage+1 < lastPage && <button onClick={()=>onPageChange(currentPage+1)}>{currentPage+1}</button>}
          {currentPage+2 < lastPage && <button onClick={()=>onPageChange(currentPage+2)}>{currentPage+2}</button>}
          {currentPage+3 < lastPage && <button onClick={()=>onPageChange(currentPage+3)}>{currentPage+3}</button>}
          {currentPage+4 < lastPage && <button onClick={()=>onPageChange(currentPage+4)}>{currentPage+4}</button>}

          {currentPage+5 < lastPage && <p disabled>...</p>}

          {currentPage !== lastPage && <button onClick={()=>onPageChange(lastPage)}>{lastPage}</button>}
        </React.Fragment>
        <button disabled={currentPage+1 === lastPage || 
          currentPage+2 === lastPage || 
          currentPage+3 === lastPage ||
          currentPage+4 === lastPage ||
          currentPage === lastPage} onClick={()=>onPageChange(currentPage+5)}>Next</button>
      </div>
    )
  }
}

Paginator.propTypes = {
  currentPage:PropTypes.number.isRequired,
  lastPage:PropTypes.number,
  onPageChange:PropTypes.func.isRequired
};

export default AsyncTable;